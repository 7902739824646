import _ from 'lodash'
import { calculate, createScorePanel } from '../../../../va-corejs-v3/specializations'
import { getTotalBonusDemeritScoreRecursively, toFixed1IfDecimal } from '../../../../va-corejs-v3/utils'
import scopePropsMap from '../../../components/scoring/_parts/helper/scopePropsMap'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'

export const productName = (product, lang) => {
  const brand = _.find(product.props, prop => prop.slug === scopePropsMap.brand)
  const model = _.find(product.props, prop => prop.slug === scopePropsMap.model)

  return `${brand?.value?.body?.[lang]} ${model?.value?.body?.[lang]}`
}

export const calcScoreContribution = (node, nodeDefinition, productNodes) => {
  const parentNode = _.find(productNodes, _node => _node.node_definition_id === nodeDefinition.parent_id)

  if (!parentNode) {
    return null
  }

  const parentMaxScore = parentNode.max_score ?? parentNode.maxScore

  const { score } = node
  const { weight, type } = nodeDefinition

  // This is legacy: item scores are saved already weighted
  const weightedScore = type === nodeDefinitionTypeMap.criterion ? score * weight : score

  if (parentMaxScore === 0) {
    return 0
  }

  return weightedScore / parentMaxScore
}

export const calcDeltaScoreContribution = (node, nodeDefinition, productNodes) => {
  const parentNode = _.find(productNodes, _node => _node.node_definition_id === nodeDefinition.parent_id)

  if (!parentNode) {
    return null
  }

  const { max_score: parentMaxScore } = parentNode
  const { weight } = nodeDefinition

  const weightedScore = weight * 1

  if (parentMaxScore === 0) {
    return 0
  }

  return weightedScore / parentMaxScore
}

export const calcNodeScore = (node, nodeDefinition, template, product) => {
  const { is_enabled: isEnabled } = node
  const { type, bonus_demerit: bonusDemerit } = nodeDefinition

  if (!isEnabled) {
    return null
  }

  if (bonusDemerit && type !== nodeDefinitionTypeMap.criterion) {
    const bonusDemeritScore = getTotalBonusDemeritScoreRecursively(
      _.keyBy(template.node_definitions, 'id'),
      _.keyBy(product.nodes, 'node_definition_id'),
      node
    )
    const bonusDemeritPercentage = bonusDemeritScore * parseInt(global.env.config.demerit_behavior.value, 10)
    return `${bonusDemeritPercentage.toString()}%`
  }

  return toFixed1IfDecimal(node.score)
}

export const calculateProductScore = async (product, template) => {
  // Calulate new full scoring
  await calculate(product, _.keyBy(template.node_definitions, 'id'), _.keyBy(product.nodes, 'node_definition_id'), true)

  // Calculate the new score panel and assign it to the initial product object (not the cloned one)
  product.score_panel = await createScorePanel(
    product,
    _.keyBy(product.nodes, 'node_definition_id'),
    _.keyBy(template.node_definitions, 'id')
  )

  // Update the nodes data on the initial product object (not the cloned one)
  product.nodes = _.map(product.nodes, calculatedNode => {
    // Normalize the node and removing not useful attributes
    calculatedNode.normalized_score = calculatedNode.normalizedScore
    calculatedNode.max_score = calculatedNode.maxScore
    delete calculatedNode.normalizedScore
    delete calculatedNode.maxScore
    delete calculatedNode.itemsCount
    delete calculatedNode.notScored
    delete calculatedNode.percentageScored
    delete calculatedNode.scored

    return calculatedNode
  })

  return product
}

// CALC CRITERION CALCULATED WEIGHT
//
// criteria calc weight: criteria weight * item weight * sub-family weight * family weight
// (criteria calc weight) * 100 / sum of all (criteria calc weight) in the familly

export const calcCriterionCalculatedWeight = (nodeDefinition, nodeDefinitions, nodes) => {
  if (nodeDefinition.type !== 'criterion') return null

  const indexedNodes = _.keyBy(nodes, 'node_definition_id')

  // use 0 for disabled nodes in order to not count them in the item sum
  if (!indexedNodes[nodeDefinition.id].is_enabled) return 0

  // calc criterion calcuated weight absolute

  let calcCriterionWeight

  const doCalcCriterionWeight = nodDef => {
    const parentNodeDef = nodeDefinitions[nodDef.parent_id]
    if (nodDef.type !== 'family') {
      calcCriterionWeight *= nodDef.weight
      doCalcCriterionWeight(parentNodeDef)
    } else calcCriterionWeight *= 100
  }

  calcCriterionWeight = 1

  doCalcCriterionWeight(nodeDefinition)

  // get family from criterion

  const getFamilyFromNodeDef = nodeDef => {
    const parentNodeDef = nodeDefinitions[nodeDef.parent_id]
    if (parentNodeDef.type !== 'family') {
      return getFamilyFromNodeDef(parentNodeDef)
    }
    return parentNodeDef
  }

  const family = getFamilyFromNodeDef(nodeDefinition)

  // get all active criterion from family
  const allCriterionFromFamily = Object.values(nodeDefinitions).filter(
    nodeDef =>
      nodeDef.type === 'criterion' &&
      nodeDef._left > family._left &&
      nodeDef._left < family._right &&
      indexedNodes[nodeDef.id]?.is_enabled
  )

  // calculated weight from each criterion in family
  let calcFamilyCriterionsWeight = 0

  const doCalcFamilyCriterionWeight = (nodDef, calcWeight = 1) => {
    const parentNodeDef = nodeDefinitions[nodDef.parent_id]
    if (nodDef.type !== 'family') {
      calcWeight *= nodDef.weight
      doCalcFamilyCriterionWeight(parentNodeDef, calcWeight)
    } else calcFamilyCriterionsWeight += calcWeight
  }

  // get total calculated criterion weight from family
  allCriterionFromFamily.forEach(criterion => {
    doCalcFamilyCriterionWeight(criterion)
  })

  // get calculated criterion weight
  return calcCriterionWeight / calcFamilyCriterionsWeight
}
